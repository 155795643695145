// 机构推介费
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  // 推介费查询
  {
    path: `${baseRoute}/commissionAgencyV3/search`,
    name: 'commissionAgencyV3Search',
    meta: {
      title: '机构推介费查询',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionAgencyV3/search'),
  },
  // 推介费导入消息查询
  {
    path: `${baseRoute}/commissionAgencyV3/messageSearch`,
    name: 'commissionAgencyV3MessageSearch',
    meta: {
      title: '机构推导入消息查询',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionAgencyV3/messageSearch'),
  },
];
