const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 转让
    {
        path: `${baseRoute}/assign/new/public`,
        name: 'assign-public',
        meta: {
            title: '转让申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/assign/new/public'),
    },
    // 客服审核
    {
        path: `${baseRoute}/assign/service`,
        name: 'assign-service',
        meta: {
            title: '转让>客服审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/assign/service'),
    },
    // 财务审核
    {
        path: `${baseRoute}/assign/finance`,
        name: 'assign-finance',
        meta: {
            title: '转让>结算审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/assign/finance'),
    },
    // 查询
    {
        path: `${baseRoute}/assign/search`,
        name: 'assign-search',
        meta: {
            title: '转让>转让查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/assign/search'),
    },
]