// 积分管理
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  {
    path: `${baseRoute}/integralGift/integral/manage`,
    name: 'integralManage',
    meta: {
      title: '积分管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/integralGift/integral'),
  },
  // 积分明细
  {
    path: `${baseRoute}/integralGift/integralDetail`,
    name: 'integralDetail',
    meta: {
      title: '积分明细',
      auth: true,
      cache: true,
    },
    component: _import('crm/integralGift/integralDetail'),
  },
  // 奖励规则
  {
    path: `${baseRoute}/integralGift/integralRule`,
    name: 'integralRule',
    meta: {
      title: '积分奖励规则',
      auth: true,
      cache: true,
    },
    component: _import('crm/integralGift/integralRule'),
  },
  // 积分任务
  {
    path: `${baseRoute}/integralGift/interestTask`,
    name: 'interestTask',
    meta: {
      title: '积分任务',
      auth: true,
      cache: true,
    },
    component: _import('crm/integralGift/interestTask'),
  },
]
