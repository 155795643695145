export default {
    add: '/product/add', // 新增
    edit: '/product/edit', // 编辑
    remove: '/product/remove', // 删除
    stop: '/product/stop', // 停用
    list: '/product/list', // 列表
    listEnum: '/product/listEnum', // 产品下拉列表
    getInfo: '/product/getInfo', // 详情
    listType: '/product/listType', // 产品类型列表
    saveExt: '/product/saveExt', // 保存扩展信息
    listExt: '/product/listExt', // 扩展信息列表
    saveSms: '/product/org/save', // 保存产品机构配置
    listSms: '/product/org/list', // 产品机构配置列表
    listGetOrgTree: '/product/org/getOrgTree', // 产品 机构配置树
    exportExcelProduct: '/product/exportExcelProduct', // 导出单个产品Excel
    importExcelProduct: '/product/importExcelProduct', // 导入Excel
    exportExcel: '/product/exportExcel', // 导出Excel
    exportProductOrgExcel: '/product/exportProductOrgExcel', // 导出产品与组织关系
    exportExcelProcess: '/product/exportExcelProcess', // 导出Excel产品募集进度
    listProcess: '/product/listProcess', // 产品募集进度列表
};