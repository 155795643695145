export default ({ request, url }) => ({
  /** 责任状业绩 列表 */
  CONZRZ_QUERYLIST(data) {
    return request({
      url: url.conzrz.queryList,
      data
    }).then((res) => {
      return res
    });
  },
  /** 责任状业绩 编辑 */
  CONZRZ_EDITBEAN(data) {
    return request({
      url: url.conzrz.editBean,
      data
    }).then((res) => {
      return res
    });
  },
  /** 责任状业绩 批量修改业绩归属部门 */
  CONZRZ_EDITBATCHORGCD(data) {
    return request({
      url: url.conzrz.editBatchOrgcd,
      data
    }).then((res) => {
      return res
    });
  },
  /** 责任状业绩 批量修改业绩系数 */
  CONZRZ_EDITBATCHFACTOR(data) {
    return request({
      url: url.conzrz.editBatchFactor,
      data
    }).then((res) => {
      return res
    });
  },
  /** 责任状业绩 导出Excel */
  CONZRZ_EXPORTLIST(data) {
    return request({
      url: url.conzrz.exportList,
      data,
      responseType: 'arraybuffer',
    }).then((res) => {
      return res
    });
  },
  /** 基本法业绩 列表 */
  CONJBF_QUERYLIST(data) {
    return request({
      url: url.conjbf.queryList,
      data
    }).then((res) => {
      return res
    });
  },
  /** 基本法业绩 编辑 */
  CONJBF_EDITBEAN(data) {
    return request({
      url: url.conjbf.editBean,
      data
    }).then((res) => {
      return res
    });
  },
  /** 基本法业绩 批量修改业绩归属部门 */
  CONJBF_EDITBATCHORGCD(data) {
    return request({
      url: url.conjbf.editBatchOrgcd,
      data
    }).then((res) => {
      return res
    });
  },
  /** 基本法业绩 批量修改业绩系数 */
  CONJBF_EDITBATCHFACTOR(data) {
    return request({
      url: url.conjbf.editBatchFactor,
      data
    }).then((res) => {
      return res
    });
  },
  /** 基本法业绩 导出Excel */
  CONJBF_EXPORTLIST(data) {
    return request({
      url: url.conjbf.exportList,
      data,
      responseType: 'arraybuffer',
    }).then((res) => {
      return res
    });
  },
})
