export default ({ request, url }) => ({
    // 财务报表-财务管理

    /** 获取部门下拉列表内容 */
    GETALLORGUSANALY(data) {
      return request({
        url: url.finance.common.getAllOrgUsAnaly,
        data,
      });
    },
    
    /** 经营分析责任状业绩明细 获取详情数据 */
    GETACHIEVEMENT_GETQUERYLIST(data) {
      return request({
        url: url.finance.achievement.queryList,
        data,
      });
    },
    /** 经营分析责任状业绩明细 导出数据 */
    GETACHIEVEMENT_EXPORTLIST(data) {
      return request({
        url: url.finance.achievement.exportList,
        data,
        responseType: 'arraybuffer',
      });
    },
    /** 资金现金流分析明细_到期出金 导出数据 */
    GETCASHFLOW_EXPORTMATURITYLIST(data) {
      return request({
        url: url.finance.cashflow.exportMaturityList,
        data,
        responseType: 'arraybuffer',
      });
    },
    /** 资金现金流分析明细_付息出金 导出数据 */
    GETCASHFLOW_EXPORTINTERESTLIST(data) {
      return request({
        url: url.finance.cashflow.exportInterestList,
        data,
        responseType: 'arraybuffer',
      });
    },
    /** 基本法业绩汇总 列表数据 */
    GETJBFACHIEVEMENT_GETQUERYLIST(data) {
      return request({
        url: url.finance.jbfachievement.queryList,
        data,
      });
    },
    /** 基本法业绩汇总 导出数据 */
    GETJBFACHIEVEMENT_EXPORTLIST(data) {
      return request({
        url: url.finance.jbfachievement.exportList,
        data,
        responseType: 'arraybuffer',
      });
    },
    /** 基本法业绩汇总 明细数据导出 */
    GETJBFACHIEVEMENT_EXPORTAGENTACHLIST(data) {
      return request({
        url: url.finance.jbfachievement.exportAgentAchList,
        data,
        responseType: 'arraybuffer',
      });
    },
    /** 获取字典值内容 */
    GETACHIEVEMENT_DICTIONARY(data) {
      return request({
        url: url.finance.achievement.dictionary,
        data,
      });
    },

    /** 经营分析续约明细_续约 导出数据 */
    GETRENEWANALY_EXPORTRENEWLIST(data) {
      return request({
        url: url.finance.renewAnaly.exportRenewList,
        data,
        responseType: 'arraybuffer',
      });
    },
    /** 经营分析续约明细_到期 导出数据 */
    GETRENEWANALY_EXPORTEXPIRELIST(data) {
      return request({
        url: url.finance.renewAnaly.exportExpireList,
        data,
        responseType: 'arraybuffer',
      });
    },
    
  });