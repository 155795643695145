const achievementURL = {
  /** 责任状业绩 */
  conzrz: {
    queryList: '/manage/zrzachievement/queryList', // 列表
    editBean: '/manage/zrzachievement/editBean', // 编辑
    editBatchOrgcd: '/manage/zrzachievement/editBatchOrgcd', // 批量修改业绩归属部门
    editBatchFactor: '/manage/zrzachievement/editBatchFactor', // 批量修改业绩系数
    exportList: '/manage/zrzachievement/exportList', // 导出Excel
  },
  /** 基本法业绩 */
  conjbf: {
    queryList: '/manage/jbfachievement/queryList', // 列表
    editBean: '/manage/jbfachievement/editBean', // 编辑
    editBatchOrgcd: '/manage/jbfachievement/editBatchOrgcd', // 批量修改业绩归属部门
    editBatchFactor: '/manage/jbfachievement/editBatchFactor', // 批量修改业绩系数
    exportList: '/manage/jbfachievement/exportList', // 导出Excel
  },
};

export default achievementURL;
