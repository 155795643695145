export default ({ request, url }) => ({
  //  团队
  /** 查询团队业绩目标 */
  ORG_GETGOALLIST(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.getGoalList,
      data,
    });
  },
  /** 编辑团队月度业绩目标 */
  ORG_UPDATEGOAL(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.updateGoal,
      data,
    });
  },
  /** 导入团队月度业绩目标 */
  ORG_IMPORTGOAL(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.importGoal,
      data,
    });
  },
  /** 导出团队月度业绩目标 */
  ORG_EXPORTGOAL(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.exportGoal,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 下载团队业绩目标导出模板 */
  ORG_GETGOALIMPORTTEMPLATE() {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.getGoalImportTemplate,
      responseType: 'arraybuffer',

    });
  },
  //  个人
  /** 分页查询个人业绩目标列 */
  PERSONAL_GETGOALPAGE(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.getGoalPage,
      data,
    });
  },
  /** 编辑个人月度业绩目标 */
  PERSONAL_PERSONALUPDATEGOAL(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.personalUpdateGoal,
      data,
    });
  },
  /** 下载个人业绩目标导出模板 */
  PERSONAL_PERSONALGOALIMPOALIMPORTTEMPLATE() {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.personalGetGoalImportTemplate,
      responseType: 'arraybuffer',
    });
  },
  /** 导入个人月度业绩目标 */
  PERSONAL_PERSONALIMPORTGOAL(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.personalImportGoal,
      data,
    });
  },
  /** 导出个人月度业绩目标 */
  PERSONAL_PERSONALEXPORTGOAL(data) {
    return request({
      url: url.operationAnalysisH5.managementByObjectives.personalExportGoal,
      data,
      responseType: 'arraybuffer',
    });
  },
});
