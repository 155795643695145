import axios, { AxiosInstance } from 'axios';
// import Adapter from 'axios-mock-adapter';
import { get } from 'lodash-es';

import util from '@/libs/util'
import { errorLog, errorCreate } from './tools';
import useToken from '@/hooks/use-token';
import EnumCode from './code';
import useAccount from '@/hooks/use-account';
import url from './../url';
import { useRouter, useRoute } from '@/router';
import { Message } from 'element-ui';

/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create();
  // 请求拦截
  service.interceptors.request.use(
    config => config,
    // 发送失败
    error => {
      /* eslint-disable-next-line no-console */
      console.log(error);
      return Promise.reject(error);
    },
  );
  // 响应拦截
  service.interceptors.response.use(
    response => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data;
      // 这个状态码是和后端约定的
      let { code, success, message, data } = dataAxios;

      // 根据 code 进行判断
      if (code === undefined || success === undefined) {
        return dataAxios;
      }

      // 请求失败
      if (!success) {
        const { clearAccountInfo } = useAccount();
        const router = useRouter();
        const currentRoute = useRoute();

        switch (code) {
          case EnumCode.NEED_VALIDATE_CODE:
            console.log('------------data', data);
            Message({
              message: message,
              type: 'error',
              duration: 5 * 1000,
            });
            // 如果返回了验证码 id 则直接返回
            if (data) {
              return data;
            }
            break;
          case EnumCode.VALIDATE_CODE_NULL:
            return true;

          case EnumCode.UNLOGIN:
            // relogin();
            if (currentRoute.value.fullPath.indexOf('/login') === -1) {
              clearAccountInfo();
              router.replace({
                name: 'login',
                query: {
                  redirect: currentRoute.value.fullPath,
                },
              });
            }

            break;
          case EnumCode.HAS_USER:
            return dataAxios;
          // 系统已存在相同证件号客户，是否合并客户，二次确认
          case EnumCode.HAS_CUSTOMER:
            return { code: EnumCode.HAS_CUSTOMER, data: data };
          case EnumCode.NO_AUTHORIZE: // 没有权限 
            window.location.href = `${window.origin}/#/403`
            break
        }

        errorCreate(`${message}`);
        return dataAxios;
      }

      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case '0':
          // [ 示例 ] code === 0 代表没有错误
          return data;
        //  code === 200 代表没有错误
        case '200':
          return data;
        default:
          // 不是正确的 code
          errorCreate(`${dataAxios.msg}: ${response.config.url}`);
          break;
      }
    },
    error => {
      const status = get(error, 'response.status');
      switch (status) {
        case 400:
          error.message = '请求错误';
          break;
        case 401:
          error.message = '未授权，请登录';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器内部错误';
          break;
        case 501:
          error.message = '服务未实现';
          break;
        case 502:
          error.message = '网关错误';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '网关超时';
          break;
        case 505:
          error.message = 'HTTP版本不受支持';
          break;
        default:
          break;
      }
      errorLog(error);
      return Promise.reject(error);
    },
  );
  return service;
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    const configDefault = {
      method: 'POST',
      headers: getHeaders(config),
      timeout: 60 * 1000,
      baseURL: url.baseURL,
      data: {},
    };
    return service(Object.assign(configDefault, config));
  };
}

/** 获取请求头数据 */
export const getHeaders = (config) => {
  const token = util.cookies.get('token')
  let headers = {
    'Content-Type': get(config, 'headers.Content-Type', 'application/json'),
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

// 用于真实网络请求的实例和请求方法
export const service = createService();
export const request = createRequestFunction(service);

// 用于模拟网络请求的实例和请求方法
export const serviceForMock = createService();
export const requestForMock = createRequestFunction(serviceForMock);

// 网络请求数据模拟工具
// export const mock = new Adapter(serviceForMock);
