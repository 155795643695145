// 推介费 
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  {
    path: `${baseRoute}/commissionV3/product`,
    name: 'commissionV3Product',
    meta: {
      title: '产品推介费',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionV3/product'),
  },
  {
    path: `${baseRoute}/commissionV3/proportion`,
    name: 'commissionV3Proportion',
    meta: {
      title: '推介费比例',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionV3/proportion'),
  },
  {
    path: `${baseRoute}/commissionV3/service`,
    name: 'commissionV3Service',
    meta: {
      title: '推介费计划',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionV3/service'),
  },
  {
    path: `${baseRoute}/commissionV3/allocation`,
    name: 'commissionV3Allocation',
    meta: {
      title: '推介费分配',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionV3/allocation'),
  },
  {
    path: `${baseRoute}/commissionV3/count`,
    name: 'commissionV3Count',
    meta: {
      title: '推介费审核',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionV3/count'),
  },
  {
    path: `${baseRoute}/commissionV3/money`,
    name: 'commissionV3Money',
    meta: {
      title: '推介费资金审核',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionV3/money'),
  },
  {
    path: `${baseRoute}/commissionV3/search`,
    name: 'commissionV3Search',
    meta: {
      title: '推介费查询',
      auth: true,
      cache: true,
    },
    component: _import('crm/commissionV3/search'),
  },
]
