export default ({ request, url }) => ({
  //  待办
  /** 分页列表 */
  WAITTOBEDONE_GETPAGE(data) {
    return request({
      url: url.messageManagement.waitToBeDone.getPage,
      data,
    });
  },
  /** 导出 */
  WAITTOBEDONE_XEPORT(data) {
    return request({
      url: url.messageManagement.waitToBeDone.export,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 待办续约明细列表 */
  WAITTOBEDONE_GETRENEWALDETAIL(data) {
    return request({
      url: url.messageManagement.waitToBeDone.getRenewalDetail,
      data,
    });
  },
  /** 作废 */
  WAITTOBEDONE_CANCEL(data) {
    return request({
      url: url.messageManagement.waitToBeDone.cancel,
      data,
    });
  },
  // 消息
  /** 分页列表 */
  INFORMATION_GETPAGE() {
    return request({
      url: url.messageManagement.information.getPage,
    });
  },
  /** 导出 */
  INFORMATION_XEPORT(data) {
    return request({
      url: url.messageManagement.information.export,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 消息接收者记录 */
  INFORMATION_GETRECIPIENTLOGT(data) {
    return request({
      url: url.messageManagement.information.getRecipientLogt,
      data,
    });
  },
  /** 新增 */
  INFORMATION_ADD(data) {
    return request({
      url: url.messageManagement.information.add,
      data,
    });
  },
  /** 更新 */
  INFORMATION_UPDATE(data) {
    return request({
      url: url.messageManagement.information.update,
      data,
    });
  },
  /** 作废 */
  INFORMATION_CANCEL(data) {
    return request({
      url: url.messageManagement.information.cancel,
      data,
    });
  },
  /** 删除 */
  INFORMATION_DELETE(data) {
    return request({
      url: url.messageManagement.information.delete,
      data,
    });
  },
  // 节日祝福
  /** 分页列表 */
  HOLIDAYBLESSINGS_GETPAGE(data) {
    return request({
      url: url.messageManagement.holidayBlessings.getPage,
      data
    });
  },
  /** 导出 */
  HOLIDAYBLESSINGS_XEPORT(data) {
    return request({
      url: url.messageManagement.holidayBlessings.export,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 详情 */
  HOLIDAYBLESSINGS_GETINFO(data) {
    return request({
      url: url.messageManagement.holidayBlessings.getInfo,
      data,
    });
  },
  /** 新增 */
  HOLIDAYBLESSINGS_ADD(data) {
    return request({
      url: url.messageManagement.holidayBlessings.add,
      data
    });
  },
  /** 更新 */
  HOLIDAYBLESSINGS_UPDATE(data) {
    return request({
      url: url.messageManagement.holidayBlessings.update,
      data
    });
  },
  /** 删除 */
  HOLIDAYBLESSINGS_DELETE(data) {
    return request({
      url: url.messageManagement.holidayBlessings.delete,
      data
    });
  },
});
