//帐户对帐
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  // 银行流水
  {
    path: `${baseRoute}/bank/accountStatement/search`,
    name: 'accountStatement-search',
    meta: {
      title: '银行流水导入查询',
      auth: true,
      cache: false,
    },
    component: _import('crm/bank/accountStatement/search'),
  },
  {
    path: `${baseRoute}/bank/accountStatement/messageSearch`,
    name: 'accountStatement-messageSearch',
    meta: {
      title: '银行流水导入查询',
      auth: true,
      cache: true,
    },
    component: _import('crm/bank/accountStatement/messageSearch'),
  },
];
