const memberUrl = {
  customer: {
    /* 客户管理 */
    manage: {
      /* 客户管理列表 */
      getCustomerPage: '/manage/customer/getCustomerPage',
      /* 客户添加 */
      addCustomer: '/manage/customer/addCustomer',
      /* 客户修改 */
      updateCustomer: '/manage/customer/updateCustomer',
      /* 客户详情 */
      getCustomerInfo: '/manage/customer/getCustomerInfo',
      /* 更新客户状态 */
      updateCustomerStatus: '/manage/customer/updateCustomerStatus',
      /* 删除客户 */
      deleteCustomer: '/manage/customer/deleteCustomer',
      /** 导入客户 */
      importCustomer: '/manage/customer/importCustomer',
      /** 导出客户 */
      exportCustomer: '/manage/customer/exportCustomerInfo',
      /** 转移客户 */
      transferCustomer: '/manage/customer/transferCustomer',
      /** 合并客户 */
      mergeCustomer: '/manage/customer/mergeCustomer',
      /** 获取客户的动态日志 */
      getCustomerDynamicLog: '/manage/customer/getDynamicLogByRelationId',
      /** 获取客户的合同 */
      getContractByCustomer: '/manage/customer/getContractByCustomer',
      /** 获取用户下拉选择列表 */
      getUserChooseList: '/manage/system/user/getUserChooseList',
      /** 获取活动选择会员客户分页列表 */
      getActivityChooseCustomerPage: '/manage/customer/getActivityChooseCustomerPage',
      /** 检查客户手机号唯一性 */
      checkCustomerUniqueMobile: '/manage/customer/checkCustomerUniqueMobile',
      /** 获取合同选择客户分页数据 */
      getContractChooseCustomerPage: '/manage/customer/getContractChooseCustomerPage',
      /** 获取客户简短详情数据 */
      getCustomerSimpleInfo: '/manage/customer/getCustomerSimpleInfo',
      /** 没有合同客户转移 */
      transferNonContactCustomer: '/manage/customer/transferNonContactCustomer',
      /** 判断是否可以转移当前客户 */
      checkTransferCustomerData: '/manage/customer/checkTransferCustomerData',
      /** 礼品选择客户分页列表 */
      getGiftChooseCustomerPage: '/manage/customer/getGiftChooseCustomerPage',
      /** 获取客户理顾变动记录分页列表 */
      getAgentUserLogPage: '/manage/customer/getAgentUserLogPage',
      /** 导入客户模板 */
      downloadImportTemplate: '/manage/customer/downloadImportTemplate',
      /** 客户选择数据 */
      getChoosePage: '/manage/customer/getChoosePage',
    },
    /* 审核管理 */
    audit: {
      /* 会员审核列表 */
      getMemberList: '/alumniManage/memberExamine/getMemberExamineList',
      /* 审核或者重新审核 */
      updateExamine: '/alumniManage/memberExamine/updateExamine',
      /* 审核列表数量统计 */
      auditListStatistics: '/alumniManage/memberExamine/getMemberExamineList/number',
    },
    /* 客户标签 */
    label: {
      /* 客户标签列表 */
      getCustomerLabelPage: '/manage/customer/label/getCustomerLabelPage',
      /* 添加或者修改客户标签 */
      addCustomerLabel: '/manage/customer/label/addCustomerLabel',
      /* 客户标签--删除 */
      deleteCustomerLabel: '/manage/customer/label/deleteCustomerLabel',
      /* 用户标签详情 */
      getMemberLabelInfo: '/alumniManage/memberLabel/getMemberLabelInfo',
      /* 会员标签下拉列 */
      getMemberLabelAll: '/alumniManage/userEnterpriseLabel/getMemberLabelAll',
      /* 用户绑定标签 */
      updateMemberLabelBind: '/alumniManage/memberLabel/updateMemberLabelBind',
    },
    /* 客户等级 */
    level: {
      /* 客户等级列表 */
      getCustomerLevelRulePage: '/manage/customer/rule/getCustomerLevelRulePage',
      /* 新增客户等级规则 */
      addCustomerLevelRule: '/manage/customer/rule/addCustomerLevelRule',
      /* 更新客户等级规则 */
      updateCustomerLevelRule: '/manage/customer/rule/updateCustomerLevelRule',
      /* 删除客户等级规则 */
      deleteCustomerLevelRule: '/manage/customer/rule/batchDeleteLevelRule',
      /* 获取客户等级下拉选择列表 */
      getChooseList: '/manage/customer/rule/getChooseList',
    },
    h5: {
      /* 获取客户H5账号分页列表  */
      getAccountPage: '/manage/h5Account/getAccountPage',
      /* 更新客户H5账号状态  */
      updateStatus: '/manage/h5Account/updateStatus',
      /* 导出客户H5账号数据  */
      exportAccountData: '/manage/h5Account/exportAccountData',
      /* 客户H5账号审核  */
      auditAccount: '/manage/h5Account/verifiedAccount',
      /* 客户H5账号合并客户  */
      mergeAccountCustomer: '/manage/h5Account/mergeAccountCustomer',
      /* 获取认证中的冲突客户数据  */
      getConflictCustomerList: '/manage/h5Account/getConflictCustomerList',
      /* 客户账号权益数据  */
      getAccountEntitlement: '/manage/h5Account/getAccountEntitlement',
      /* 客户账号核销权益  */
      writeOffEntitlement: '/manage/h5Account/writeOffEntitlement',
      /* 调整客户账号成长值  */
      adjustGrowthValue: '/manage/h5Account/adjustGrowthValue',
      /* 调整客户账号积分  */
      accountAdjustScore: '/manage/h5Account/accountAdjustScore',
      /* 推送指定会员数据到会员中台  */
      pushMemberToMiddlewareByIds: '/manage/h5Account/pushMemberToMiddlewareByIds',
    },
    // 客户打款提报
    paymentSubmission: {
      /*  分页数据  */
      getPage: '/manage/customerPaymentSubmission/getPage',
      /*  导出  */
      export: '/manage/customerPaymentSubmission/export',
      /*  作废  */
      cancel: '/manage/customerPaymentSubmission/cancel',
      /*  打款提报对应转化合同数据  */
      getConversionContractList: '/manage/customerPaymentSubmission/getConversionContractList',
      /*  转化  */
      convert: '/manage/customerPaymentSubmission/convert',
      /*  导入  */
      importData: '/manage/customerPaymentSubmission/importData',
      /*  下载导入模板  */
      downloadImportTemplate: '/manage/customerPaymentSubmission/downloadImportTemplate',
    },
    // 客户跟进
    follow: {
      /*  分页数据  */
      getPage: '/manage/customerFollow/getPage',
      /*  导出  */
      export: '/manage/customerFollow/export',
      /*  详情数据  */
      getInfo: '/manage/customerFollow/getInfo',
      /*  删除  */
      delete: '/manage/customerFollow/delete',
    }
  },
};

export default memberUrl;
