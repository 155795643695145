export default ({ request, url }) => ({
  /** 导出积分 */
  EXPORT_SCORE_DATA(data) {
    return request({
      url: url.score.exportScoreData,
      responseType: 'arraybuffer',
      data,
      timeout: 60000
    });
  },
  /** 批量调整积分 */
  BATCH_ADJUST_SCORE(data) {
    return request({
      url: url.score.batchAdjustScore,
      data
    });
  },
  /** 调整积分 */
  ADJUST_SCORE(data) {
    return request({
      url: url.score.adjustScore,
      data
    });
  },
  /** 导出积分明细 */
  EXPORT_SCORE_DETAIL_DATA(data) {
    return request({
      url: url.score.exportScoreDetailData,
      responseType: 'arraybuffer',
      data,
      timeout: 60000
    });
  },
  /** 获取积分明细的变动记录列表 */
  GET_CHANGE_LIST(data) {
    return request({
      url: url.score.getChangeList,
      data
    }).then((res) => {
      return res
    });
  },
  /** 获取积分奖励规则获取详情 */
  GET_SCOREINFO() {
    return request({
      url: url.score.getInfo,
    });
  },
  /** 获取积分奖励规更新详情 */
  GET_SCOREUPDETE(data) {
    return request({
      url: url.score.update,
      data
    });
  },
  // 积分规则

  /** 详情 */
  SCORERULE_GETINFO(data) {
    return request({
      url: url.scoreRule.getInfo,
      data
    });
  },
  /** 更新 */
  SCORERULE_UPDATE(data) {
    return request({
      url: url.scoreRule.update,
      data
    });
  },

  // 积分任务
  /** 分页数据 */
  SCORETASK_GETPAGE(data) {
    return request({
      url: url.scoreTask.getPage,
      data
    });
  },
  /** 新增 */
  SCORETASK_ADD(data) {
    return request({
      url: url.scoreTask.add,
      data,
    });
  },
  /** 更新 */
  SCORETASK_UPDATE(data) {
    return request({
      url: url.scoreTask.update,
      data,
    });
  },
  /** 详情 */
  SCORETASK_GETINFO(data) {
    return request({
      url: url.scoreTask.getInfo,
      data,
    });
  },
  /** 更新状态 */
  SCORETASK_UPDATESTATUS(data) {
    return request({
      url: url.scoreTask.updateStatus,
      data,
    });
  },
  /** 删除 */
  SCORETASK_DELETE(data) {
    return request({
      url: url.scoreTask.delete,
      data,
    });
  },
})
