const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  // 责任状业绩
  {
    path: `${baseRoute}/achievement/conzrz`,
    name: 'achievementConzrz',
    meta: {
      title: '责任状业绩',
      auth: true,
      cache: true,
    },
    component: _import('crm/achievement/conzrz'),
  },
  // 基本法业绩
  {
    path: `${baseRoute}/achievement/conjbf`,
    name: 'achievementConjbf',
    meta: {
      title: '基本法业绩',
      auth: true,
      cache: true,
    },
    component: _import('crm/achievement/conjbf'),
  }
];
