// 机构推介费
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  // 推介费查询
  {
    path: `${baseRoute}/regionalOperation/search`,
    name: 'regionalOperationSearch',
    meta: {
      title: '区域经营查询',
      auth: true,
      cache: true,
    },
    component: _import('crm/regionalOperation/search'),
  },
  // 推介费导入消息查询
  {
    path: `${baseRoute}/regionalOperation/messageSearch`,
    name: 'regionalOperationMessageSearch',
    meta: {
      title: '导入消息查询',
      auth: true,
      cache: true,
    },
    component: _import('crm/regionalOperation/messageSearch'),
  },
];
